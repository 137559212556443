import type { IErrorMessages } from '@components/auth/types';
import type { ActionFunctionArgs, TypedResponse } from '@remix-run/node';
import { useActionData, useNavigation } from '@remix-run/react';
import { useCallback, useEffect, useState } from 'react';

type V = ({ request }: ActionFunctionArgs) => Promise<
  | TypedResponse<never>
  | {
      error: string;
    }
  | { errors: IErrorMessages }
>;

export const useActionError = <
  T extends V,
  K extends string | IErrorMessages = string,
>() => {
  const actionData = useActionData<T>();
  const navigation = useNavigation();

  const [errorMessage, setErrorMessage] = useState<K>();

  useEffect(() => {
    if (actionData && navigation.state === 'idle') {
      if ('error' in actionData) setErrorMessage(actionData?.error as K);
      else setErrorMessage(actionData?.errors as K);
    }
  }, [navigation.state, actionData]);

  const releaseErrorMessage = useCallback(() => {
    setErrorMessage(undefined);
  }, []);

  return { errorMessage, releaseErrorMessage };
};
